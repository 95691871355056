import React from 'react';
import { Page, Text } from '@geist-ui/core'
import './Works.css';
function Works() {
  return (
    <div className="About">
      <Page>
        <Page.Header>
          <Text h3>我的作品</Text>
        </Page.Header>
        <Page.Content>


        </Page.Content>
        <Page.Footer>
          <Text small>Copyright © 2022 by  Huizhong</Text>
        </Page.Footer>
      </Page>
    </div>
  );
}


export default Works;
